export const validExerciseType = (type) => {
    let accept = ["exercise", "assignment"];
    return accept.includes(type);
};

export const fixedNumber = (number, length = 2) => {
    if (!number) return 0;
    // Handle comma decimal separator by replacing with period
    const normalizedNumber = String(number).replace(',', '.');
    const formatted = Number(normalizedNumber).toFixed(length);
    return parseFloat(formatted); // Convert back to number to remove trailing zeros
};

import { Button, Descriptions, Modal, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { getDataToSubmit, validateDataToSubmit } from "../utils/submit";
import { ExerciseContext } from "../../../stores/exerciseStore";
import { StoreContext } from "../../../stores";
import { handleFetchApi } from "../../../configs/apiRequest";
import { fixedNumber } from "../../../utils/validation";
import { useTranslation } from "react-i18next";
import RatingByMark from "../../../components/RatingByMark";
import moment from "moment";
import { ExerciseModeEnums } from "../../../configs/enums";
import CustomWarningIcon from "../../../components/Icons/CustomWarningIcon";
import Timeout from "./Timeout";
import { AASSIGNMENT } from "../../../apis/exercise";
import CustomDangerIcon from "../../../components/Icons/CustomDangerIcon";
import { isEmpty } from "../../../utils";
import { jumpToExercise } from "../utils";

function SubHeader(props) {
    const { t } = useTranslation();
    const { me, exerciseChildren } = useContext(StoreContext);
    const { selectedExercise, setSelectedExercise, choice, matched, fill, record, writing, dragList, setExerciseResult, isDoing, exerciseResult } =
        useContext(ExerciseContext);
    
    const submitAssignment = async () => {
        const dataToSubmit = getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });

        const result = await handleFetchApi(AASSIGNMENT.submit({ gradingId: selectedExercise?.id, data: dataToSubmit }));
        if (result?.status === "AS0006") {
            Modal.error({
                title: t("ex_submit_err"),
                content: t("ex_submit_err_content"),
            });
        }
        if (result?.status === "S0000") {
            const { exerciseResult, evaluation, startedAt, submittedAt } = result?.data;
            let comment = evaluation?.comment;
            Modal.success({
                title: "Hoàn thành bài kiểm tra",
                okText: "ĐÓNG",
                width: "clamp(400px, 60dvw, 768px)",
                icon: <RatingByMark mark={evaluation?.mark} />,
                footer: null,
                className: `exercise-result --centered ${
                    evaluation?.mark < 5 ? "low-score" : evaluation?.mark >= 5 && evaluation?.mark <= 8 ? "medium-score" : "high-score"
                }`,
                centered: true,
                content: (
                    <div className="assignment-result">
                        {evaluation && (
                            <Typography.Title level={3} className="assignment-result__mark">
                                {fixedNumber(evaluation?.mark)}
                                <small> / 10</small>
                            </Typography.Title>
                        )}

                        <Typography.Text className="text--left">
                            {t("comment")}: <b>{comment ?? t("no_data")}</b>
                        </Typography.Text>
                        <Typography.Text className="text--left">
                            {t("time_doing_assignment")}: &nbsp;
                            <b>
                                {moment(startedAt).format("HH:mm")} - {moment(submittedAt).format("HH:mm")} {moment(submittedAt).format("DD/MM/YYYY")}
                            </b>
                        </Typography.Text>
                        {!evaluation && (
                            <>
                                <br />
                                <Typography.Text type="danger" style={{ textAlign: "center" }}>
                                    Bài tập hiện chưa thể tính điểm. <br /> Hãy chờ giáo viên của bạn kiểm tra nhé !
                                </Typography.Text>
                            </>
                        )}
                    </div>
                ),
            });
            const exerciseResultGroupById = new Map(exerciseResult.map((result) => [result.item_id, result]));
            setExerciseResult(exerciseResultGroupById);
            setSelectedExercise((prev) => ({ ...prev, evaluation: evaluation, exerciseMode: ExerciseModeEnums.REVIEW }));
        }
    };

    const submitExercise = async () => {
        const dataToSubmit = getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });

        const result = await handleFetchApi(AASSIGNMENT.submitExercise({ data: dataToSubmit[0] }));

        if (result?.status === "AS0006") {
            Modal.error({
                title: t("ex_submit_err"),
                content: t("ex_submit_err_content"),
            });
        }
        if (result?.status === "S0000") {
            const { evaluation, startedAt, submittedAt } = result?.data;
            let comment = evaluation?.comment;
            Modal.success({
                title: "Hoàn thành bài kiểm tra",
                okText: "ĐÓNG",
                icon: <RatingByMark mark={evaluation?.mark} />,
                footer: null,
                className: `exercise-result --centered ${
                    evaluation?.mark < 5 ? "low-score" : evaluation?.mark >= 5 && evaluation?.mark <= 8 ? "medium-score" : "high-score"
                }`,
                centered: true,
                content: (
                    <div className="assignment-result">
                        <Typography.Title level={3} className="assignment-result__mark">
                            {fixedNumber(evaluation?.mark)}
                            <small> / 10</small>
                        </Typography.Title>

                        <Typography.Text className="text--left">
                            Nhận xét: <b>{comment ?? "Chưa có nhận xét"}</b>
                        </Typography.Text>
                        <Typography.Text className="text--left">
                            Bắt đầu: <b>{moment(startedAt).format("DD/MM/YYYY HH:mm")}</b>
                        </Typography.Text>
                        <Typography.Text className="text--left">
                            Nộp bài: <b>{moment(submittedAt).format("DD/MM/YYYY HH:mm")}</b>
                        </Typography.Text>

                        {!evaluation && (
                            <Typography.Text type="danger" style={{ textAlign: "center" }}>
                                Bài tập hiện chưa thể tính điểm. Hãy chờ giáo viên của bạn kiểm tra nhé !
                            </Typography.Text>
                        )}
                    </div>
                ),
            });
            const exerciseResultGroupById = new Map([result?.data]?.map((result) => [result.item_id, result]));
            setExerciseResult(exerciseResultGroupById);
            setSelectedExercise((prev) => ({ ...prev, evaluation: evaluation, exerciseMode: ExerciseModeEnums.REVIEW }));
        }
    };

    const alertSubmit = () => {
        Modal.confirm({
            title: "Hãy kiểm tra lại nhé",
            content: "Bạn chưa hoàn thành hết tất cả các bài tập, nếu nộp ngay lúc này, bạn sẽ không đạt được điểm tuyệt đối.",
            centered: true,
            icon: <CustomDangerIcon />,
            okText: "Vẫn nộp",
            cancelText: t("back"),
            onOk: () => {
                if (selectedExercise?.type === "exercise") submitExercise();
                else submitAssignment();
            },
            okType: "danger",
        });
    };

    const handleSubmit = () => {
        const dataToSubmit = getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });
        const isValid = validateDataToSubmit({ dataToSubmit, exerciseChildren });
        if (!isValid) alertSubmit();
        else {
            let alertText = "Chúc bạn đạt kết quả tốt!";
            Modal.confirm({
                title: "Bạn có thực sự muốn nộp bài?",
                content: alertText,
                okText: "NỘP BÀI",
                cancelText: "Quay lại",
                icon: <CustomWarningIcon />,
                centered: true,
                onOk: () => {
                    if (selectedExercise?.type === "exercise") submitExercise();
                    else submitAssignment();
                },
            });
        }
    };

    const autoSave = async () => {
        const dataToSave = getDataToSubmit({
            selectedExercise,
            user_id: me?.id,
            choice,
            record,
            fill,
            matched,
            writing,
            dragList,
            exerciseChildren,
            exerciseResult,
        });
        if (dataToSave) {
            await handleFetchApi(AASSIGNMENT.save({ gradingId: selectedExercise?.id, data: dataToSave }));
        }
    };

    useEffect(() => {
        if (selectedExercise?.type !== "exercise") {
            const interval = setInterval(() => {
                isDoing() && autoSave();
            }, 5000);

            return () => clearInterval(interval); // Cleanup on unmount
        }
    });

    if (selectedExercise?.submittedAt || !isDoing()) return;
    return (
        <div className="flex__between__center exercise_subheader">
            <Timeout onSubmit={submitAssignment} />
            <Button type="primary" size="large" onClick={handleSubmit}>
                NỘP BÀI
            </Button>
        </div>
    );
}

export default SubHeader;

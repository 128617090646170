import { ExerciseModeEnums } from "../../../configs/enums";

export const saveChoice = ({ choice, setChoice, item }) => {
    let parent_id = item.parent_item?.id;
    let key = item.content.v_string?.slice(0, -1) + `_${parent_id}`;
    setChoice((prevState) => ({
        ...prevState,
        [key]: [
            {
                value: item.content.v_string?.slice(-1), // remove old value
                id: item.id,
                parent_id: parent_id,
            },
        ],
    }));
};

export const saveTickCross = ({ choice, setChoice, item, value }) => {
    let parent_id = item.parent_item?.id;
    let key = `${item?.name}_${parent_id}`;
    setChoice((prevState) => ({
        ...prevState,
        [key]: {
            value: value,
            id: item?.id,
        },
    }));
};

export const updateSelectedExercise = (data, fn) => {
    data?.data?.exerciseResult?.forEach((e) => {
        fn((prevState) => ({
            ...prevState,
            [e.item_id]: e,
        }));
    });
    fn((prevState) => ({
        ...prevState,
        isSubmitted: true,
        assignmentGrading: data?.data,
    }));
};

export const getElementStatus = (type, parentId, itemId, exerciseResult) => {
    switch (type) {
        case "choice":
            return exerciseResult[`${itemId}_${parentId}`];

        default:
            break;
    }
};

export const scaleUnit = {
    1: 1,
    0: 0.9,
    "-1": 0.8,
    "-2": 0.7,
    "-3": 0.6,
    "-4": 0.5,
    "-5": 0.4,
};

export const normalizeApostrophes = (text) => {
    if (!text) return text; // Trả về nếu chuỗi rỗng hoặc undefined
    const apostrophes = /['’‘ʻ´`]/g; // Regex bao gồm các dạng apostrophe
    return text.replace(apostrophes, "'");
}

export const getXYLine = (id1, id2, scale = 1) => {    
    let div1 = document.getElementById(id1);
    let div2 = document.getElementById(id2);
    let x1 = div1?.offsetLeft + div1?.offsetWidth / 2;
    let y1 = div1?.offsetTop + div1?.offsetHeight / 2;
    let x2 = div2?.offsetLeft + div2?.offsetWidth / 2;
    let y2 = div2?.offsetTop + div2?.offsetHeight / 2;
    if (x2 - x1 > 150) {
        x1 += div1?.offsetWidth / 2;
        x2 -= div2?.offsetWidth / 2;
    }
    if (x1 - x2 > 150) {
        x1 -= div1?.offsetWidth / 2;
        x2 += div2?.offsetWidth / 2;
    }
    if (y2 - y1 > 150) {
        y1 += div1?.offsetHeight / 2 - 20;
        y2 -= div2?.offsetHeight / 2 - 20;
    }
    if (y1 - y2 > 150) {
        y1 -= div1?.offsetHeight / 2 - 20;
        y2 += div2?.offsetHeight / 2 - 20;
    }
    return {
        x1: x1 / scaleUnit[scale],
        y1: y1 / scaleUnit[scale],
        x2: x2 / scaleUnit[scale],
        y2: y2 / scaleUnit[scale],
        id: `${id1}_${id2}`,
    };
};

export const getObjByKey = (magic_key) => {
    let splitKey = magic_key?.split("_");
    return {
        label: splitKey[0],
        elementId: splitKey[1],
        parentId: splitKey[2],
    };
};

export const handleNextTab = (setActiveKey, setMedia, exerciseChildren, exerciseMode, id) => {
    setActiveKey(id);
    const currentExercise = exerciseChildren.get(id);
    const IMAGE = currentExercise?.parent_item?.content?.img_url || currentExercise?.content?.secondary_img_url;

    if (exerciseMode === ExerciseModeEnums.DOING) {
        setMedia((prevState) => ({
            ...prevState,
            current: id,
            autoplay: true,
            type: IMAGE ? "IMAGE" : "AUDIO",
            children: IMAGE,
        }));
    }
};

export const jumpToExercise = (exerciseId) => {
    const element = document.getElementById(exerciseId);

    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
    } else {
        console.error(`Element with id ${exerciseId} not found.`);
    }
};

export const jumpToDraff = (exerciseId) => {
    const element = document.getElementById(exerciseId);

    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
    } else {
        console.error(`Element with id ${exerciseId} not found.`);
    }
};

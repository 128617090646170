/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { ExerciseModeEnums } from "../configs/enums";

export const ExerciseContext = React.createContext(null);

export default ({ children }) => {
    const [pending, setPending] = useState();
    const [matched, setMatched] = useState([]);
    const [choice, setChoice] = useState({});
    const [fill, setFill] = useState({});
    const [writing, setWriting] = useState({});
    const [record, setRecord] = useState({});
    const [scale, setScale] = useState(1);
    const [exams, setExams] = useState([]);
    const [selecting, setSelecting] = useState();
    const [lines, setLines] = useState({});
    const [dragList, setDragList] = useState({});
    const [selectedExercise, setSelectedExercise] = useState({});
    const [exerciseResult, setExerciseResult] = useState(new Map());

    const isDoing = () => selectedExercise.exerciseMode === ExerciseModeEnums.DOING;
    const isReview = () => selectedExercise.exerciseMode === ExerciseModeEnums.REVIEW;

    const stores = {
        pending,
        setPending,
        matched,
        setMatched,
        fill,
        setFill,
        choice,
        setChoice,
        writing,
        setWriting,
        record,
        setRecord,
        scale,
        setScale,
        exams,
        setExams,
        setSelecting,
        selecting,
        lines,
        setLines,
        dragList,
        setDragList,
        selectedExercise,
        setSelectedExercise,
        exerciseResult,
        setExerciseResult,
        isDoing,
        isReview,
    };

    return <ExerciseContext.Provider value={stores}>{children}</ExerciseContext.Provider>;
};
